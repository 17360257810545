import MenuLink from '@/components/Navbar/ui/MenuLink';
import { useBreakpoints } from '@/shared/providers/Device';
import { MenuLinkStoryblok, MenuStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import styles from '../Footer.module.scss';
const Menu = ({ blok }: MenuStoryblok) => {
    const { isExtraLarge, isLarge } = useBreakpoints();
    const isMobile = !isExtraLarge && !isLarge;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: isMobile ? blok.flexDirectionMobile : blok.flexDirection,
                marginBottom: isMobile ? '16px' : '0px',
            }}
            {...storyblokEditable({ blok })}
        >
            {blok.link.map((nestedBlok: MenuLinkStoryblok, index: number) => (
                <div
                    style={{ marginRight: blok.linksMarginRight + 'px' }}
                    className={styles.footerLink}
                    key={nestedBlok._uid}
                >
                    <MenuLink blok={nestedBlok} index={index} />
                </div>
            ))}
        </div>
    );
};
export default Menu;
